$(function() {
  var configList = {
    "profile": {"screenName": 'FlockSoftware'},
    "maxTweets": 1,
    "domId": "tweet-content",
    "enableLinks": true, 
    "showUser": false,
    "showTime": false,
    "showInteraction": false,
    "showImages": false,
    "showPermalinks": false,
    "linksInNewWindow": true,
    // "showRetweet": false,
    // "customCallback": handleTweets,
    // "dataOnly": true,
    "lang": 'en'
  };

  if($('#tweet-content').length) {
    twitterFetcher.fetch(configList);
  }
})