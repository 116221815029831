$(function() {
  $(document).ready(function() {
    if(Flock.Utils.getUrlVar('password_entry') == "true"){
      if(Flock.Utils.getUrlVar('email')){
        $('#email_or_username, #email').val(decodeURIComponent(Flock.Utils.getUrlVar('email')));
      }
      showNormalLogin();
    }
    var cookie_accepted_date = localStorage.getItem('flock_cookie_accepted_date');
    var cookie_accepted_duration = 0;
    if(cookie_accepted_date) {
      cookie_accepted_duration = Math.ceil((new Date().getTime() - parseInt(cookie_accepted_date)) / (1000 * 60 * 60 * 24));
    }
    if((!cookie_accepted_date || cookie_accepted_duration > 30) && $('.js-cookie-banner-el').length) {
      $('.js-cookie-banner-el').toggleClass('d-flex d-none');
    }
    $(document).on('click', '.js-cookie-continue', function(e) {
      $('.js-cookie-banner-el').toggleClass('d-flex d-none');
      var now = new Date();
      localStorage.setItem('flock_cookie_accepted_date', now.getTime());
    });
    $(".js-alternative-view-link").on("click", function(e) {
      e.preventDefault();
      $(".js-normal-view").hide();
      $(".js-alternative-view").show();
    });
    $(".js-normal-view-link").on("click", function(e) {
      e.preventDefault();
      showNormalLogin();
    });
    $(".js-sso-login-view-link").on("click", function(e) {
      e.preventDefault();
      $(".js-normal-view").hide();
      $(".js-sso-login-view").show();
    });
    $(".js-okta-login-view-link").on("click", function(e) {
      e.preventDefault();
      $(".js-normal-view").hide();
      $(".js-okta-login-view").show();
    });
    $(".js-one-login-view-link").on("click", function(e) {
      e.preventDefault();
      $(".js-normal-view").hide();
      $(".js-one-login-view").show();
    });
  });
  $(".js-ssn").mask("000-00-0000");
  $("#signup__submit").click(function(e) {
    var form = document.getElementById("new_user");
    if (form.checkValidity()) {
      $(this).prop("disabled", true);
      $(this)
        .closest("form")
        .submit();
    }
  });
  $("#flash-out.alert, #flash-area.alert, #flash-message.alert,#fade-out.alert").fadeOut(5000);
  var showNormalLogin = function(){
    $(".js-alternative-view").hide();
    $(".js-sso-login-view").hide();
    $(".js-normal-view").show();
  }

  hideHeaderFooter();
  function hideHeaderFooter() {
    var currPath = window.location.pathname;
    if (currPath == "/signup" || currPath == "/covid-19_time_off/signup") {
      $(".flock-navbar").hide();
      $("footer").hide();
    }
  }

  // this code runs after reload if and only if a user signed up with a urlId in location.hash
  var urlId;

  if ((urlId = sessionStorage.getItem("headlineId"))) {
    // a <noscript> tag was provided, but since we handle all sign ups with
    // AJAX, there will be no noscript conversions to track.
    $.getScript("//www.googleadservices.com/pagead/conversion.js", function() {
      window.google_trackConversion({
        google_conversion_id: 949525065,
        google_conversion_language: "en",
        google_conversion_format: "3",
        google_conversion_color: "ffffff",
        google_conversion_label: "0DckCMXTomMQybTixAM",
        google_remarketing_only: false,
        google_conversion_value: urlId,
      });
    });
  }

  function hasAValue(el) {
    return $.trim($(el).val());
  }

  function clearErrors($couldHaveErrors) {
    _($couldHaveErrors).each(function(el) {
      $(el).removeClass("has-error");
      $(".error-text").remove();
    });
  }
  var randomImage = {
    paths: [
      "https://static.helloflock.com/assets/logIn/1.jpg",
      "https://static.helloflock.com/assets/logIn/2.jpg",
      "https://static.helloflock.com/assets/logIn/3.jpg",
      "https://static.helloflock.com/assets/logIn/4.jpg",
      "https://static.helloflock.com/assets/logIn/5.jpg",
      "https://static.helloflock.com/assets/logIn/6.jpg",
      "https://static.helloflock.com/assets/logIn/7.jpg",
    ],

    generate: function() {
      var path = randomImage.paths[Math.floor(Math.random() * randomImage.paths.length)];
      $("#randomImage").css("background-image", "url(" + path + ")");
    },
  };
  randomImage.generate();
});
