// $(function() {
//   $('#burger').click(function(e) {
//     e.preventDefault()
//     $('#nav-menu').slideToggle()
//   })

//   $(window).scroll(function() {
//   if ($(this).scrollTop() > 1) {
//       $('#nav-header').addClass('opaque')
//     }
//     else{
//       $('#nav-header').removeClass('opaque')
//     }
//   });
// })

$(function() {
  $(".navbar-toggle").click(function(e) {
    e.preventDefault();
    $("#navbarCollapse").slideToggle();
  });
  // showAnimations();
  // /** ===========================================
  //   Hide / show the master navigation menu
  // ============================================ */
  // function showAnimations() {
  //   var previousScroll = 0;
  //   // $(".flock-navbar").removeClass('is-hidden').addClass('is-visible').addClass('position-static');
  //   // $(window).scroll(function(){
  //   //     var currentScroll = $(this).scrollTop();
  //   //     /*
  //   //       If the current scroll position is greater than 0 (the top) AND the current scroll position is less than the document height minus the window height (the bottom) run the navigation if/else statement.
  //   //     */
  //   //     if (currentScroll > 0 && currentScroll < $(document).height() - $(window).height()){
  //   //       /*
  //   //         If the current scroll is greater than the previous scroll (i.e we're scrolling down the page), hide the nav.
  //   //       */
  //   //       if (currentScroll > previousScroll){
  //   //         window.setTimeout(showNav, 300);
  //   //       /*
  //   //         Else we are scrolling up (i.e the previous scroll is greater than the current scroll), so show the nav.
  //   //       */
  //   //       } else {
  //   //         window.setTimeout(showNav, 300);
  //   //       }
  //   //       /*
  //   //         Set the previous scroll value equal to the current scroll.
  //   //       */
  //   //       previousScroll = currentScroll;
  //   //     }

  //   //     if(currentScroll === 0) {
  //   //       window.setTimeout(hideNav, 300);
  //   //     }
  //   // });
  // }

  // function hideNav() {
  //   $(".flock-navbar").addClass("is-hidden").removeClass("is-visible");
  // }
  // function showNav() {
  //   $(".flock-navbar").removeClass("is-hidden").addClass("is-visible");
  // }

  $("form").submit(function() {
    $("body.mobile-app").prepend("<div id='overlay-spinner'></div>");
  });
});
